;(function () {
  
  $('body').on('click', '[data-copy-btn]', function (e) {
    e.preventDefault();
    
    var trigger = $(this);
    
    var input = trigger.siblings('[data-copy-input]');
    
    if(!input || !input.length) return;
  
    input[0].select();
    document.execCommand("copy");
  });
  
}());