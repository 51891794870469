$(document).ready(function () {
	
	$('body').on('click', '[data-tab-btn]', function (e) {
		var $this = $(this);
		//если это не радиобаттон, отменяем стандартное событие
		!$this.is('input[type="radio"]') ? e.preventDefault() : null;
		
		// debugger;
		var tabsGroup = $this.closest('[data-tabs-wrap]');
		var tabName = $this.attr('data-tab-btn') || '';
		var tabSelect = tabsGroup.find('[data-tab-select]');
		var selectTrigger = tabSelect.children('[data-tab-select-trigger]');
		var selectTriggerVal = selectTrigger.children('span');
		var tabBlock;
		
		if ($this.hasClass('_open')) return;
		
		if(tabName === 'all') {
			tabBlock = tabsGroup.find('[data-tab-block]');
			tabBlock.addClass('_open');
			tabsGroup.find('._open[data-tab-btn]').removeClass('_open');
      $this.addClass('_open');
			return;
		}
		
		var tabBlock = tabsGroup.find('[data-tab-block="' + tabName + '"]');
		var activeBtn = tabsGroup.find('[data-tab-btn="' + tabName + '"]');
		
		
		if (!tabBlock.length) return;
    
    tabsGroup.find('._open[data-tab-btn]').removeClass('_open');
    tabsGroup.find('._open[data-tab-block]').removeClass('_open');
    
    tabBlock.addClass('_open');
    activeBtn.addClass('_open');
		
		if(tabSelect.length) {
			selectTriggerVal.html(activeBtn.html());
		}
	});
	
	$('body').on('click', '[data-tab-select-trigger]', function (e) {
		var trigger = $(this);
		var $item = trigger.closest('[data-tab-select]');

		var block = $item.find('[data-tab-select-block]');

		var dur = 400;
		var selectTab = $item.find('[data-tab-btn]');

		e.preventDefault();
		if ($item.hasClass('_open')) {
			close();
		} else {
			open();

			$(document).on('mouseup.clickoutSelect', function (e) {

				if (!$item.is(e.target) && $item.has(e.target).length === 0) {
					close();
				}
			});
		}

		selectTab.on('click', function (e) {
			close();
		});

		function open() {
			$item.addClass('_open');
			block.slideDown(dur);
		}

		function close() {
			$item.removeClass('_open');
			block.slideUp(dur);
			$(document).off('.clickoutSelect');
		}
	});
	
});


