;(function () {
  var $body = $('body');
  var customTitle;
  var timer;
  
  $body.on('mouseenter', '[data-title]', function (e) {

    if($(window).width() <= 800) return;

    var $item = $(this);
    var titleData = $item.attr('data-title');
    var titleTemplate = '<div class="custom-title"><span class="custom-title__text">' + titleData + '</span></div>';
    
    var x, y, positionLeft, positionTop;
    
    if ($('.custom-title')) {
      $('.custom-title').remove();
    }
    x = e.clientX;
    y = e.clientY;
    
    clearTimeout(timer);
    timer = setTimeout(function () {
      $body.append(titleTemplate);
      customTitle = $('.custom-title');
      positionLeft = x - customTitle.width();
      positionTop = y;
      
      if(positionLeft < 0) {
        positionLeft = 0
      }
      
      customTitle.css({
        'top': positionTop + 'px',
        'left': positionLeft + 'px',
        'opacity': 1
      });
      
    }, 1000);
  
    $item.on('mouseleave', function (e) {
      clearTimeout(timer);
      if(!customTitle || !customTitle.length) return;
      customTitle.css({
        'opacity': 0
      });
    
      customTitle[0].addEventListener('transitionend', function () {
        customTitle.remove();
      })
    
    });
    
  })
  
}());