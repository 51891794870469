;(function () {
	
	$('body').on('click', '.show-pass', function (e) {
		e.preventDefault();
		
		var trigger = $(this);
		
		var item = trigger.closest('.form-control-label');
		var input = trigger.siblings('.form-control');
		
		if(item.hasClass('_visible-pass')) {
			item.removeClass('_visible-pass');
			input.attr('type', 'password');
		} else {
			item.addClass('_visible-pass');
			input.attr('type', 'text');
		}
		
	});
	
}());