;(function () {
  var $body = $('body');
  var customTooltip;

  $body.on('click', '[data-tooltip]', function (e) {
    e.preventDefault();
  });

  $body.on('mouseenter', '[data-tooltip]', function (e) {
    if ($('.custom-tooltip')) {
      $('.custom-tooltip').remove();
    }

    var $item = $(this);
    var tooltipData = $item.attr('data-tooltip');
    var tooltipTemplate = '<div class="custom-tooltip"><span class="custom-tooltip__text">' + tooltipData + '</span><span class="custom-tooltip__arrow"></span></div>';

    $body.append(tooltipTemplate);
    customTooltip = $('.custom-tooltip');
    var tooltipArrow = customTooltip.find('.custom-tooltip__arrow');
    
    var itemCoords = $item[0].getBoundingClientRect();
    
    var positionLeft, positionTop, offsetRight, arrowTop, arrowLeft, arrowTransform;
    var fullWidth = false;
    var offsetTooltip = 5;
    
    positionLeft = itemCoords.left + $item.outerWidth() / 2;
    offsetRight = positionLeft + customTooltip.outerWidth();
    
    if (positionLeft < 0) {
      positionLeft = 0;
    } else if (offsetRight > $(window).width()) {
      positionLeft = (itemCoords.right - $item.outerWidth() / 2) - customTooltip.outerWidth();
      
      if (positionLeft < 0) {
        positionLeft = 0;
        fullWidth = true;
      }
      leftTop();
    } else {
      rightTop();
    }
    
    customTooltip.css({
      'width': fullWidth ? '100vw' : ''
    });
    
    if (fullWidth) {
      customTooltip.addClass('_full');
    }
    
    positionTop = itemCoords.top - customTooltip.innerHeight() - tooltipArrow.outerHeight() - offsetTooltip;
    
    arrowTop = itemCoords.top - tooltipArrow.outerHeight() - offsetTooltip;
    arrowLeft = itemCoords.left + ($item.outerWidth() - tooltipArrow.outerWidth()) / 2;
    arrowTransform = 'none';
    
    if (positionTop < 15) {
      positionTop = itemCoords.bottom + tooltipArrow.outerHeight() + offsetTooltip;
      if (customTooltip.hasClass('_left-top')) {
        leftBottom();
      } else {
        rightBottom();
      }
      
      arrowTop = (itemCoords.bottom + offsetTooltip + 1);
      arrowTransform = 'scaleY(-1)';
    }
    
    customTooltip.css({
      'left': positionLeft + 'px',
      'top': positionTop + 'px',
      'opacity': 1
    });
    
    if (fullWidth) {
      tooltipArrow.css({
        'top': arrowTop + 'px',
        'left': arrowLeft + 'px',
        'transform': arrowTransform
      });
    }
    
    
    $item.on('mouseleave', function (e) {
      customTooltip.css({
        'opacity': 0
      });
      
      customTooltip[0].addEventListener('transitionend', function () {
        customTooltip.remove();
      })
      
    });
    
    function rightTop () {
      resetClass();
      customTooltip.addClass('_right-top');
    }
    
    function rightBottom () {
      resetClass();
      customTooltip.addClass('_right-bottom');
    }
    
    function leftTop () {
      resetClass();
      customTooltip.addClass('_left-top');
    }
    
    function leftBottom () {
      resetClass();
      customTooltip.addClass('_left-bottom');
    }
    
    function resetClass () {
      customTooltip.removeClass('_right-bottom _right-top _left-top _left-bottom');
    }
  })
  
}());