;var modalOpen,modalClose;

$(document).ready(function () {
	
	$('body').on('click', '[data-modal-trigger]', function (e) {
		var trigger = $(this);
		
		var name = trigger.attr('data-modal-trigger');
    
    var modal = $('[data-modal="' + name + '"]');
    
    if (modal.length == 0) return;
    e.preventDefault();
    
    modalOpen(modal);
	});
  
  modalOpen = function (modalElem) {
  	var modal = $(modalElem);
  	
    if (modal.length == 0) return;
  	
    var name = modal.attr('data-modal');
    
    var trigger = $('[data-modal-trigger="' + name + '"]');
    
    var closeTrigger = modal.find('[data-modal-close]');
  
    var scrollEl = modal.find('[data-modal-scroll]')[0];
  
    var modalOuter = modal.find('[data-modal-outer]');
  
    if (modal.hasClass('_open')) {
      modalClose(modal);
    } else {
      open();
    
      $(document).on('mouseup.modalOut', function (e) {
        if (!modalOuter.length) {
          if (!trigger.is(e.target) && trigger.has(e.target).length === 0
              && !modal.is(e.target) && modal.has(e.target).length === 0) {
            modalClose(modal);
          }
        } else if (!trigger.is(e.target) && trigger.has(e.target).length === 0
            && !modalOuter.is(e.target) && modalOuter.has(e.target).length === 0) {
          modalClose(modal);
        }
      });
    }
  
    closeTrigger.click(function (e) {
      e.preventDefault();
      modalClose(modal);
    });
    
    function open() {
      trigger.addClass('_open');
      modal.addClass('_open');
  
      $('html').addClass('_stop-scroll');
  
      bodyScrollLock.disableBodyScroll(scrollEl);
      // console.log('open modal');
    }
	};
	
	modalClose = function (modalElem) {
    var modal = $(modalElem);
		
		if (!modal.length) {
			modal = $('[data-modal]._open');
		}
    
    var name = modal.attr('data-modal');
    var trigger = $('[data-modal-trigger="' + name + '"]');
    var scrollEl = modal.find('[data-modal-scroll]')[0];
		
		$('html').removeClass('_stop-scroll');
		bodyScrollLock.enableBodyScroll(scrollEl);
		
		modal.removeClass('_open');
		trigger.removeClass('_open');
		$(document).off('.modalOut');
		// console.log('close modal');
	}
});