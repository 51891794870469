$(document).ready(function () {
  var timer;

  function checkAccordion() {
    $('[data-accordion-wrap]').each(function (i, wrap) {
      var $wrap = $(wrap);
      var wrapName = $wrap.attr('data-accordion-wrap') || '';

      var $items = $wrap.find('[data-accordion-item="' + wrapName + '"]');

      var maxWidth = +$wrap.attr('data-max-width');
      var minWidth = +$wrap.attr('data-min-width');
      var winWidth = $(window).width();

      $items.each(function (i, item) {
        var $item = $(item);
        var $itemContent = $item.find('[data-accordion-block="' + wrapName + '"]');

        if (maxWidth && winWidth > maxWidth || minWidth && winWidth < minWidth) {
          $item.removeClass('_open');
          $itemContent.css('display', '');
        } else {
          if($item.hasClass('_open')) {
            $itemContent.css('display', 'block')
          } else {
            $itemContent.css('display', 'none')
          }
        }
      });
    });
  }

  checkAccordion();

  $(window).resize(function () {
    clearTimeout(timer);
    timer = setTimeout(function () {
      checkAccordion();
    }, 500);
  });

  $('body').on('click', '[data-accordion-link]', f);

  function f(e) {
    var $link = $(this);
    var $wrap = $link.closest('[data-accordion-wrap]');
    var linkName = $link.attr('data-accordion-link') || '';
    var wrapName = $wrap.attr('data-accordion-wrap') || '';

    if (wrapName !== linkName) return;

    var maxWidth = +$wrap.attr('data-max-width');
    var minWidth = +$wrap.attr('data-min-width');
    var winWidth = $(window).width();
    if (maxWidth && winWidth > maxWidth || minWidth && winWidth < minWidth) return;

    e.preventDefault();

    var item = $link.closest('[data-accordion-item="' + wrapName + '"]');
    var itemContent = item.find('[data-accordion-block="' + wrapName + '"]');

    if (item.hasClass('_open')) {
      itemContent.slideUp(400);
      item.removeClass('_open');
    } else {

      // var activeItems = $wrap.find('._open[data-accordion-item]');
      // activeItems.removeClass('_open').find('[data-accordion-block]').slideUp(200);

      var activeItems = $wrap.find('._open[data-accordion-item="' + wrapName + '"]');
      activeItems.removeClass('_open').find('[data-accordion-block="' + wrapName + '"]').slideUp(200);

      itemContent.slideDown(400);
      item.addClass('_open');
    }
  }

});
