;var popupOpen,popupClose;

$(document).ready(function () {
	
	$('body').on('click', '[data-popup-trigger]', function (e) {
		var trigger = $(this);
		
		var name = trigger.attr('data-popup-trigger');
		
		var popup = '[data-popup="' + name + '"]';
    
    if (popup.length == 0) return;
    e.preventDefault();
    
    popupOpen(popup);
	});
	
	popupOpen = function(popupElem) {
    var popup = $(popupElem);
    
    if(popup.length == 0) return;
    
    var name = popup.attr('data-popup');
    var trigger = $('[data-popup-trigger="' + name + '"]');
    
    var closeTrigger = popup.find('[data-popup-close]');
    
    if (popup.hasClass('_open')) {
      popupClose(popup);
    } else {
      open();
      
      if(!popup.hasClass('_can-click-out')) {
        $(document).on('mouseup.popupOut', function (e) {
          if (!trigger.is(e.target) && trigger.has(e.target).length === 0
              && !popup.is(e.target) && popup.has(e.target).length === 0) {
            popupClose(popup);
          }
        });
      }
    }
    
    closeTrigger.click(function (e) {
      e.preventDefault();
      popupClose(popup);
    });
		
    function open() {
      trigger.addClass('_open');
      popup.addClass('_open');
    }
	};
	
	popupClose = function (popupElem) {
    var popup = $(popupElem);
    
		if(!popup.length) {
      popup = $('[data-popup]._open');
		}
		
    var name = popup.attr('data-popup');
    var trigger = $('[data-popup-trigger="' + name + '"]');
    
    popup.removeClass('_open');
    trigger.removeClass('_open');
    
    if(!popup.hasClass('_can-click-out')) {
      $(document).off('.popupOut');
    }
	}
});